import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faAngellist } from '@fortawesome/free-brands-svg-icons/faAngellist';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
	{
		link: 'https://github.com/ryankashi',
		label: 'Github',
		icon: faGithub
	},
	// {
	// 	link: 'https://facebook.com/d',
	// 	label: 'Facebook',
	// 	icon: faFacebookF
	// },
	{
		link: 'https://www.instagram.com/ryankashi/',
		label: 'Instagram',
		icon: faInstagram
	},
	{
		link: 'https://www.linkedin.com/in/ryankashi',
		label: 'LinkedIn',
		icon: faLinkedinIn
	},
	// {
	// 	link: 'https://angel.co/Ryan-d-Kashi',
	// 	label: 'Angel List',
	// 	icon: faAngellist
	// },
	// {
	// 	link: 'https://twitter.com/ryankashi',
	// 	label: 'Twitter',
	// 	icon: faTwitter
	// },
	{
		link: 'mailto:ryanakashi@gmail.com',
		label: 'Email',
		icon: faEnvelope
	}
];

export default data;
