import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
	<section id="sidebar">
		<section id="intro">
			<Link to="/" className="logo">
				<img src={`${PUBLIC_URL}/images/me_2.jpg`} alt="" />
			</Link>
			<header>
				<h2>Ryan Kashi</h2>
				<p>
					<a href="mailto:ryanakashi@gmail.com">ryanakashi@gmail.com</a>
				</p>
			</header>
		</section>

		<section className="blurb">
			<h2>About</h2>
			<p>Hi, I'm Ryan Kashi. I am a UCSB and UC Berkeley graduate, and HF0 alum. I dropped out of my PhD at USC to become a startup founder.</p>
			<p>Now, I am the CTO at Datum Technologies, an Avenue Capital subsidiary building custom software solutions for medium (50MM+) to large size commercial real estate ventures.</p>
			<p>Previously, I was the solo founder and CEO of Tenami - the first premium content marketplace for livestream influencers.</p>
			{/* <p>
				Hi, I'm Ryan. I am a <a href="https://icme.stanford.edu/">Stanford ICME</a> graduate, YC Alumni, and the VP of Engineering at <a href="https://smileidentity.com">Smile Identity</a>. Previously, I was the co-founder and CTO of <a href="https://arthena.com">Arthena</a>, co-founder of <a href="https://matroid.com">Matroid</a>, and worked at <a href="https://planet.com">Planet</a>{' '}
				and <a href="https://facebook.com">Facebook</a>.
			</p> */}
			<ul className="actions">
				<li>
					{!window.location.pathname.includes('/resume') ? (
						<Link to="/resume" className="button">
							Learn More
						</Link>
					) : (
						<Link to="/about" className="button">
							About Me
						</Link>
					)}
				</li>
			</ul>
		</section>

		<section id="footer">
			<ContactIcons />
			<p className="copyright">
				&copy; Ryan Kashi <Link to="/">ryankashi.com</Link>.
			</p>
		</section>
	</section>
);

export default SideBar;
