import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Analytics from '../components/Template/Analytics';
import Navigation from '../components/Template/Navigation';
import SideBar from '../components/Template/SideBar';
import ScrollToTop from '../components/Template/ScrollToTop';


import * as amplitude from '@amplitude/analytics-browser';
import { useLocation } from 'react-router-dom';
amplitude.init('3fb65f9457fd76125f3b92de4cf1d712');

const Main = (props) => {
	const { pathname } = useLocation();
	useEffect(() => {
		amplitude.track(window.location.href);
	}, [pathname]);
	return (
		<HelmetProvider>
			<Analytics />
			<ScrollToTop />
			<Helmet titleTemplate="%s | Ryan Kashi" defaultTitle="Ryan Kashi" defer={false}>
				{props.title && <title>{props.title}</title>}
				<meta name="description" content={props.description} />
			</Helmet>
			<div id="wrapper">
				<Navigation />
				<div id="main">{props.children}</div>
				{props.fullPage ? null : <SideBar />}
			</div>
		</HelmetProvider>
	);
};

Main.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	fullPage: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string
};

Main.defaultProps = {
	children: null,
	fullPage: false,
	title: null,
	description: "Ryan Kashi's personal website."
};

export default Main;
