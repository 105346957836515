import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const { NODE_ENV, REACT_APP_GA_TRACKING_ID } = process.env;

if (NODE_ENV === 'production') {
	// eslint-disable-next-line import/no-named-as-default-member
	ReactGA.initialize(REACT_APP_GA_TRACKING_ID);
}

const Analytics = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		if (NODE_ENV === 'production') {
			// eslint-disable-next-line import/no-named-as-default-member
			ReactGA.set({
				page: pathname
			});
			// eslint-disable-next-line import/no-named-as-default-member
			ReactGA.pageview(pathname);
		}
	}, [pathname]);

	return null;
};

export default Analytics;
